import React, { useEffect } from "react"

import Layout from "../components/Layout/Layout"
import SEO from "../components/SEO/SEO"
import Loyalty from "../components/LoyaltyNaming/LoyaltyNaming"
import BottomNav from "../components/Offering/BottomNav/BottomNav"

import { AppProvider } from "../context/AppContext"

const LoyaltyNaming = () => {
  return (
    <AppProvider>
      <Layout>
        <main>
          <Loyalty />
        </main>
      </Layout>
    </AppProvider>
  )
}

export default LoyaltyNaming
