import React from "react"
import classes from "./LoyaltyNaming.module.scss"
import buildingLogo from "../../images/peab_building.png"
import { Link } from "gatsby"

import MainButton from "../MainButton/MainButton"

const LoyaltyNaming = () => {
  return (
    <section className={classes.LoyaltySection}>
      <div className={classes.Container}>
        <Link to="/" className={classes.Logo}>
          <img src={buildingLogo} alt="Peabody's Building Logo" id="top" />
        </Link>
        <h1>Name the Peabody's Loyalty Program!</h1>
        <p>
          Peabody's Wine & Beer Merchants is pleased to announce that we will
          soon offer a loyalty program to reward our customers with deals on
          their favorite products! However, we need your help to name our new
          program. The winning entry will be awarded a $100 gift card to be used
          on anything in the shop!
        </p>
        <form
          name="loyalty"
          method="post"
          action="/loyalty-form-success"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="loyalty" />
          <p>
            <label>
              Loyalty name entry
              <input
                type="text"
                name="loyalty-name"
                placeholder="Loyalty name entry"
                aria-label="Loyalty name entry"
                required
              />
            </label>
          </p>
          <p>
            <label>
              Enter your email address
              <input
                type="email"
                name="email"
                placeholder="Email address"
                aria-label="Email address"
                required
              />
            </label>
          </p>
          <p>
            <MainButton type="submit">Submit</MainButton>
          </p>
        </form>
      </div>
    </section>
  )
}

export default LoyaltyNaming
