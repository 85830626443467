import React from "react"
import classes from "./BottomNav.module.scss"
import { AnchorLink } from "@jamesmallette/gatsby-plugin-anchor-links"
import mailToLink from "../../../data/marchWineEmail"

const BackToTop = () => {
  return (
    <div className={classes.Container}>
      <a href={mailToLink} className="mailtoui">
        <button className={`${classes.Btn} ${classes.WineBtn}`}>
          Buy Wine
        </button>
      </a>
      <AnchorLink to="/march-wine-offering/#top">
        <button className={classes.Btn}>Back To Top</button>
      </AnchorLink>
    </div>
  )
}

export default BackToTop
